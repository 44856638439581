import React from 'react';
import "./App.css";
import TypeContainer from "./Components/TypeContainer";
import Navbar from "./Components/Navbar";
import tree_1 from "./Components/images/trees_4.svg";
import tree_2 from "./Components/images/trees_5.svg";
import birds from "./Components/images/birds_1.svg";
import linkedin from "./Components/images/linkedin.png";
import insta from "./Components/images/instagram.png";
import git from "./Components/images/github.png";
import Info from "./Components/Info";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Plan from './Components/Plan';

function App() {
  return (
    <div className="App">
      <div className="navbar">
        {/* Render the Navbar component within the Router */}
        <Router>
          <Navbar />
        </Router>
      </div>
      <img className="tree-1" src={tree_1} alt="" />
      <img className="tree-1" src={tree_2} alt="" />
      <img className="birds" src={birds} alt="" />
      <Router>
        <Routes>
          <Route path="/" element={<TypeContainer />} />
          <Route path="/about-us" element={<Info />} />
          <Route path="/future-plans" element={<Plan/>} />
          <Route path="/ForestFingers" element={<Navigate to="/" replace />} />
          
          
        </Routes>
      </Router>
      <div className="footer">
        <div>
          <a className="linkedin" target='_blank ' href="https://www.linkedin.com/in/imdeepakvermaa/">
            LinkedIn
            <img className="img" src={linkedin} alt="" height={30} width={30} />
          </a>
        </div>
        <div>
          <a className="github" target='_blank ' href="https://github.com/imdeepakvermaa">
            Github
            <img className="img" src={git} alt="" height={30} width={30} />
          </a>
        </div>
        <div>
          <a className="instagram" target='_blank ' href="https://www.instagram.com/imdeepakvermaa/">
            Instagram
            <img className="img" src={insta} alt="" height={30} width={30} />
          </a>
        </div>

      </div>
    </div>
  );
}

export default App;
