import React from "react";
import "./info.css";

function Info() {
  return (
    <div className="info-main-div">
      <p className="info-paragraph">
        <h1>Welcome to FastType</h1>
        <br />
        <h2>About FastType:</h2>
        <p>
          FastType is an engaging and educational web application designed to
          help users improve their typing speed and accuracy. Whether you're a
          student, a professional, or simply interested in enhancing your
          keyboarding skills, my platform offers a fun and effective way to
          test and develop your typing abilities.
        </p>

        <br />
        <h2>Features:</h2>

        <h3>Typing Speed Test:</h3>
        <p>
          FastType provides a user-friendly typing test that challenges users to
          type a given set of words or sentences as accurately and quickly as
          possible.
        </p>

        <h3>Real-time Feedback:</h3>
        <p>
          As users type, they'll receive real-time feedback, including their
          typing speed (words per minute) and accuracy percentage.
        </p>

        <h3>Visualizations:</h3>
        <p>
          What sets FastType apart is its ability to display users' typing speed
          and accuracy results in a graphical format. Users can track their
          progress and identify areas for improvement through our intuitive
          charts and graphs.
        </p>

        <h3>Customizable Tests:</h3>
        <p>
          Tailor your typing tests to your preferences. You can choose different
          text categories and difficulty levels to make your practice more
          enjoyable and challenging.
        </p>

        <br />
        <h2>Why FastType?</h2>

        <h3>Skill Improvement:</h3>
        <p>
          Regular typing tests can help users enhance their typing skills,
          making them more efficient in their daily tasks and activities.
        </p>

        <h3>Career Advancement:</h3>
        <p>
          Typing is an essential skill for many professions. Improve your typing
          speed to boost your productivity and advance in your career.
        </p>

        <h3>Educational:</h3>
        <p>
          FastType is a valuable resource for students and educators. Enhance
          your typing abilities for school assignments and academic success.
        </p>

        <h3>Fun and Interactive:</h3>
        <p>
          Our platform turns typing practice into an enjoyable experience,
          helping users stay motivated to achieve their goals.
        </p>
      </p>
    </div>
  );
}

export default Info;
