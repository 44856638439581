import React from 'react'

const Plan = () => {
  return (
    <div className="info-main-div">
    <p className="info-paragraph">
      <h1>Future Plans</h1>
      <br />
      <h2>Will Add More Features To The Platform Such as:</h2>
      <h3> - Texts Format levels :- Basics, Intermediate, Advanced.</h3>
      <h3> - Modes :- Day Mode and Night Mode </h3>
      And Many More........

    </p>
  </div>
  )
}

export default Plan;
